.slick-slide.slick-active.slick-current .up {
  opacity: 0;
  animation: up 2.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s 1 forwards;
  /* animation: name duration timing-function delay iteration-count direction
    fill-mode; */
}
.slick-slide.slick-cloned .up,
.slick-slide.slick-cloned .down {
  opacity: 0;
}
.up {
  opacity: 0;
}
.up p {
  margin: 30px 0;
  font-size: 42px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: "Playfair Display", serif;
  font-style: italic;
}
@keyframes up {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  65% {
    transform: translateY(-15%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.slick-slide.slick-active.slick-current .down {
  opacity: 0;
  animation: down 2.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s 1 forwards;
}

.down p {
  margin: 30px 0;
  font-size: 42px;
  font-weight: 700;
  color: rgb(132, 154, 132);
  font-family: "Playfair Display", serif;
  font-style: italic;
}
@keyframes down {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  65% {
    transform: translateY(20%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media screen and (max-width: 420px) {
  .up p {
    display: none;
  }
}
