

* {
  margin: 0;
  padding: 0;
  border-style: none;
 outline: none;
  box-sizing: border-box;
  /* font-family: sans-serif; */
}
.slick-vertical .slick-slide{
  border: 0;
}